<template>
  <!-- 商家入驻协议http://app.cmcqs.com/join.html -->
  <div class="p15 lh24px">
    <div class="tc font24">商家入驻协议</div>
    <p class="mt10">
      访问、浏览或使用<span class="red">舒昕逸家技师端</span
      >提供服务者端软件，以下统称"舒昕逸家技师端”，表明您已阅读、理解并同意接受以下条款的约束，并遵守所有适用的法律和法规。您一旦使用舒昕逸家技师端，则须秉着诚信的原则遵守以下条款。
    </p>
    <div class="h6">一般原则：</div>
    <p>
      以下规则适用于所有使用上门服务的用户或本应用程序的浏览者，且该等规则及条款将不时进行修订。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被本应用程序中某些页面上或某些具体服务明确指定的法律通告或条款所取代，您应该了解这些内容，一旦接受本条款，即意味着您已经同时详细阅读并接受了这些被引用或取代的条款。
    </p>
    <div class="h6">权利说明：</div>
    <p>
      舒昕逸家（重庆）科技有限公司及其关联公司对其发行的或与合作公司共同发行的包括但不限于舒昕逸家技师端软件及相关产品或服务的全部内容，享有知识产权，受法律保护。如果相关内容未含权利声明，并不代表舒昕逸家技师端对其不享有权利和不主张权利，您应根据法律、法规及诚信原则尊重权利人的合法权益并合法使用该内容。未经舒昕逸家（重庆）科技有限公司书面许可，任何单位及个人不得以任何方式或理由对上述软件、产品、服务、信息、文字的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售，或以超级链路连接或传送、存储于信息检索系统或者其他任何商业目的的使用，但对于非商业目的的、个人使用的下载或打印（未作修改，且须保留该内容中的版权说明或其他所有权的说明）除外。本应用程序中使用和显示的商标和标识（以下统称"商标"）是舒昕逸家（重庆）科技有限公司及其关联公司注册和未注册的有关商标，受法律保护，未经舒昕逸家（重庆）科技有限公司书面许可，任何人不得以任何方式使用，但注明属于其他方拥有的商标、标志、商号除外。
    </p>
    <div class="h6">责任限制声明：</div>
    <p>
      不论在何种情况下，舒昕逸家对由于信息网络设备维护、信息网络连接故障、智能终端、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务不承担责任。无论在任何情况下（包括但不限于疏忽原因），由于使用本应用程序上的信息或通过本应用程序链接的信息，或其他与本应用程序链接的网站信息，对您或他人所造成任何的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，智能终端系统之损坏或数据丟失等后果），均由使用者自行承担责任（包括但不限于疏忽责任）。
    </p>
    <p>
      本应用程序所载的信息，包括但不限于文本、图片、数据、观点、网页或链接，虽然力图准确和详尽，但舒昕逸家并不就其所包含的信息和内容的准确、完整、充分和可靠性做任何承诺。舒昕逸家表明不对这些信息和内容的错误或遗漏承担责任，也不对这些信息和内容作出任何明示或默示的、包括但不限于没有侵犯第三方权利、质量和没有智能终端病毒的保证。
    </p>
    <p>
      于本应用程序中积分商城平台上所提及或展示的非上门理疗的服务或产品，我们仅提供基本信息。舒昕逸家不是相关产品的生产者或经销者，亦不是服务的提供方。舒昕逸家不就商城上提供的产品或服务做出任何声明或保证，所有展示的产品和服务应受其本公司或服务提供方所做质量承诺和条款的约束。
    </p>
    <div class="h6">第三方链接：</div>
    <p>
      本应用程序可能保留有第三方网站或网址的链接，访问这些链接将由用户自己作出决定，舒昕逸家并不就这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。舒昕逸家没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。如果您决定访问任何与本站链接的第三方网站，其可能带来的结果和风险全部由您自己承担。
    </p>
    <div class="h6">适用法律和管辖权：</div>
    <p>
      通过访问本应用程序或使用本应用程序提供上门服务，即表示您同意该访问或服务受中华人民共和国法律的约束，且您同意受中华人民共和国法院的管辖。访问或接受服务过程中发生的争议应当协商解决，协商不成的，各方一致同意至舒昕逸家（重庆）科技有限公司住所所在地有管辖权的法院诉讼解决。
    </p>
    <!-- 居间服务合作协议 -->
    <div class="tc mt20 font20">居间服务合作协议</div>
    <p>
      舒昕逸家（重庆）科技有限公司及其指定分公司或关联公司（以下合称"我司"、或"平台”）作为舒昕逸家上门服务的信息平台经营者，根据用户需求向使用上门服务应用程序的用户提供服务，平台根据用户发出的服务需求信息，经过后台大数据信息处理，在用户终端上提供可使用的服务者以及服务类型信息，并由最终匹配成功的服务者实际向用户提供线下上门服务。在您使用本平台提供线下居间服务之前，请您认真阅读本协议（尤其是粗体标注部分）。
    </p>
    <p>
      本协议内容包括本协议正文、我司已经发布或即将发布的平台规则、《软件使用协议》及《个人信息保护及隐私政策》。上述协议与规则均被视为本协议不可分割的一部分，与本协议正文具有同等的法律效力。一旦您登陆本应用程序、通过本应用程序接单或服务用户，则视作您已充分阅读并接受本协议的所有条款，您同意本协议对您和我司具有法律约束力。如果您不同意本协议，则不能登陆应用程序、接单或服务用户。
    </p>
    <div class="h6">第一条 服务合作期间</div>
    <p>
      我司与您向用户提供上门服务的居间合作期间为订单成立时起至用户结束或者提前结束订单止。您打开服务终端的时间区间为我司为您提供信息服务的期间，但并非您通过我司平台为用户提供线下理疗服务的合作期间。在订单成立前或用户结束或提前结束后，您所处于听单阶段，为我司为您提供信息服务及与您共同合作的准备期。在该时间区间内，您无需寻找用户，平台会将新订单信息发送至您的应用程序终端。您在使用本应用程序过程中，我司将接入第三方软件为您提供地图导航服务。您理解并同意，地图导航服务所提供的路线规划、服务导航、交通路况等功能和服务仅供您参考。如果您发现地图数据有任何错误或不足，请您及时通过交通上报功能或客服渠道进行反馈。
    </p>
    <div class="h6">第二条 服务合作费用</div>
    <p>
      您同意我司按照不时更新的费用结算标准向您因上述合作定期或不定期进行结算。
    </p>
    <div class="h6">第三条 服务保障</div>
    <p>
      我司在与您的合作中，根据法律法规的要求承担相应的法律责任。在您接单或服务用户的过程中，您需要按照本协议的约定及相关平台规则的规定对服务质量和安全予以保证，我司将按照平台规则对您的行为进行监督。舒昕逸家上门服务信息平台将通过个性化的安全保障产品为标有保障产品的订单服务中的全部用户提供相应的安全保障，例如对于治安事故或其他意外事故，由于您的过错造成的侵权损害或您因违约给我司造成的损失，我司有权向您进行追偿。
    </p>
    <div class="h6">第四条 提供服务者违约责任</div>
    <p>
      您应该按照本协议约定的条款履行约定。如您违反本协议约定，应承担相应违约责任。您理解并同意，我司有权根据平台规则中约定的违约认定程序和标准来认定您是否违约。
      如您的行为构成违约的，我司可根据本协议约定、相应的平台规则规定对您采取暂停账户提现功能、中止部分或全部合作、划扣违约金、终止合作等措施。由于您的违约行为导致我司遭受的任何损失，我司有权向您进行追偿。
    </p>
    <div class="h6">第五条 取消违约责任</div>
    <p>您取消订单，将根据平台规则承担相应违约责任。</p>
    <div class="h6">第六条 作弊违约责任</div>
    <p>
      通过各种形式的刷单、作弊行为骗取补贴、奖励等利益的行为涉嫌违法犯罪，我司对该等行为将依法予以严厉打击。您应对刷单、作弊及类似的欺骗行为承担相应的违约责任，包括但不限于：支付违约金、暂停接受服务、终止合作等。同时我司保留诉诸法律的权利以及随时对承担违约责任的方式和标准进行调整的权利。
    </p>
    <div class="h6">第七条 承诺与保证</div>
    <p>您登陆应用程序、通过应用程序接单或服务用户，您做出如下保证及承诺：</p>
    <p>
      (一）
      您的身体状况以及拥有的技能资格应当符合平台的要求，否则平台有权不予准入，已经准入的，平台有权永久停止服务，给其他用户或者平台造成损失的，平台有权依法追究其法律责任。
    </p>
    <p>(二） 您使用的移动设备满足如下手机硬件准入标准：</p>
    <p>
      舒昕逸家应用程序适用于ANDROID操作系统为ANDR0ID2.3以上版本及操作系统为IOS7.0以上的苹果手机。对于在其他操作系统使用应用程序可能出现的任何问题，我司不承担任何责任。
    </p>
    <p>（三）禁止私下承揽业务</p>
    <p>
      在您提供服务时，除现有订单之服务用户另行要求增加服务事项外，不得私下与用户达成交易，并应即时向平台报告增加之服务事项，否则，我司有权根据平台规则中约定的违约认定程序和标准来认定您是否违约。
    </p>
    <p>
      (四）您知悉并保证，您在本协议合作期间应遵守国家法律，不应从事如下违反法律的规定，损害他人合法权益或扰乱平台工作秩序的行为：
    </p>
    <p>
      1)
      扰乱平台工作秩序。包括但不限于围攻、打砸平台工作场所、扰乱平台活动现场秩序等；
    </p>
    <p>
      2)
      侵犯平台工作人员人身权利。包括但不限于伤害他人身体、对他人进行性骚扰、偷拍他人、散布他人隐私、威胁他人人身安全等；
    </p>
    <p>3 )侵犯平台或工作人员的财产权利。包括但不限于抢夺财物、损毁财物等。</p>
    <p>
      如您违反本条款的约定，情节较轻的，我司可以中止服务合作，直至您承诺改正为止；情节严重的，我司可以在提前通知您后立即终止本协议。
    </p>
    <div class="h6">第八条 特别约定</div>
    <p>您认可与我们（具体公司列表见此处）建立合作关系。</p>
    <p>
      本协议内容包括本协议条款以及已经发布的及将来可能发布的与您有关的各项平台规则及其他约定，该等约定均为本协议不可分割的一部分，与本协议具有同等法律效力，在本协议履行过程中，我司可视情况对本协议进行修改。一旦本协议的内容发生变动，我司将通过平台公布最新的协议，不再向您作个别通知。如果您不同意我司对本协议及有关约定所做的修改，您有权停止使用我司服务。
      如果您继续使用我司服务，则视为您接受我司对本协议及有关约定所做的修改，并应遵照修改后的协议执行。
    </p>
    <p>
      在您注册、使用应用程序及提供服务过程中，您同意并提供您的姓名、照片、劳动技能或资格证书、手机号码、手机位置数据等个人信息，此类信息将仅限于满足上门服务，我司将采取合理的措施保护您的个人隐私，除法律或有法律赋予权限的政府部门要求或您同意等原因外，我司未经您同意不会向除合作单位以外的第三方公开、透露您的个人信息或使用您的个人信息开展其他业务。
    </p>
    <p>
      为维护和保障提供服务者的合法权益，提升平台中全体用户的满意程度，我们建议您定期了解并学习包括相关法律法规、平台运行规则和服务规范、安全服务等方面的知识，您也可以参加经过平台认证的第三方培训机构提供的平台服务技能培训课程，提高自身服务能力和竞争力。
    </p>
    <p>
      在接单过程中，您的订单详情、位置信息、服务行为等，均会纳入平台安全监督体系，请您按照平台规则规范您的行为。在应用程序使用过程中将产生通信数据流量费用，该服务与费用由您的通信运营商提供和收取，我司不收取通信服务和流量费用。
    </p>
    <p>
      本协议中约定的内容，如与现行法律、行政法规之明确规范存在冲突，适用相应法律、行政法规之规定。
    </p>
    <p class="c3 fb">
      本协议正文及其附件受《合同法》等民事法律约束。我司与所有提供上门服务的服务者仅存在居间合作关系，不存在任何直接或间接的劳动关系，不适用《劳动法》、《劳动合同法》、《社会保险法》、《住房公积金管理条例》等法律法规。
    </p>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.h6 {
  color: #333;
  font-size: 14px;
  margin: 6px 0;
  font-weight: bold;
}
.red {
  color: red;
}
</style>
